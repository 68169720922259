/* Ensure html and body have no overflow */
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.navbar {
  
      background-size: 400% 400%;
      animation: gradientAnimation 10s ease infinite;
      overflow: hidden;
      border-radius: 8px;
      background-color: transparent; /* Transparent background */
      backdrop-filter: blur(20px); /* Blurry effect */
      overflow: hidden;
      z-index: 10;
}

.navbar li {
  float: left;
      display: block;
      color: #fff; /* Text color */
      text-align: center;
      padding: 14px 20px; /* Padding */
      text-decoration: none;
      font-size: 17px; /* Font size */
      transition: background-color 0.3s, color 0.3s; /* Smooth transition */
      border-radius: 20px;
      text-decoration: none;
      position: relative;
      
      
}

.navbar li::after {
  content: '';
  position: absolute;
  width: 0;
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: white;
  transition: width 0.3s ease-in-out;
}

.navbar li:hover::after {
  width: 100%;
}

/* write */

.write{
  font-family: 'Roboto', sans-serif;
  color: #fff;

}
.write1{
  /* font-family: 'Merriweather', serif; */
  font-family: 'Roboto', sans-serif;
  color: black;

}
.writeblr{
  backdrop-filter: blur(20px);
}

.inputs{
  height: 40px;
  width: 180px;
  border-radius: 20px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);
}




.inputs1{
  height: 40px;
  width: 250px;
  border-radius: 20px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 20px rgb(169, 198, 231);
  transform: scale(1);
  color: black;
  transition: transform 0.4s
  ease-in-out;
}

.inputs1:hover{
  transform: scale(1.2)
}


/* .background{
  /* background-image: url('./Images/back.jpg'); */
  /* background-color: rgba(54, 50, 50, 0.856); */
  /* background: linear-gradient(to right, #ec9262, #e7b261, #E0AC69, #C68642, #8B4513); */
/* }  */

.background1{
  background: linear-gradient(to right, #07121f7e, #07121fb9);
  /* background-image: linear-gradient(to left, #BDBBBE 0%, #9D9EA3 100%), radial-gradient(88% 271%, rgba(255, 255, 255, 0.25) 0%, rgba(254, 254, 254, 0.25) 1%, rgba(0, 0, 0, 0.25) 100%), radial-gradient(50% 100%, rgba(255, 255, 255, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%);
  background-blend-mode: normal, lighten, soft-light; */

  /* background-color: #E4E4E1;
 background-image: radial-gradient(at top center, rgba(255,255,255,0.03) 0%, rgba(0,0,0,0.03) 100%), linear-gradient(to top, rgba(255,255,255,0.1) 0%, rgba(92, 98, 102, 0.6) 100%);
 	background-blend-mode: normal, multiply; */
 
}

.background2{
  /* background: linear-gradient(to right, #327eda31, #327eda49); */
  background: linear-gradient(to right, #327eda31, #327eda49);

}

.background3{
  /* background-image: linear-gradient( 135deg, #FEC163 10%,#FBAB7E, #515ba9c0 100%); */
    background-image: linear-gradient( 135deg, #FEC163 10%,#FBAB7E, #515ba9c0 100%);


}
.background4{
  background-image: linear-gradient( 109.6deg,  rgba(5,85,84,1) 11.2%, rgba(64,224,208,1) 91.1% );

}
.background5{
  /* background-image: linear-gradient( 45deg,  rgba(253,189,85,1) 7.8%, rgba(249,131,255,1) 100.3% ); */
  background-image: linear-gradient( 20deg, #FEC163 10%,#FBAB7E, #515ba9c0 100%);

}
.background6{
  background: linear-gradient(180deg,  #07121f7e, #07121fb9);
 
}



.home-img{
  height: 100%;
  background-image: url('../public/islamic/QuranReading.jpg');
  background-image: linear-gradient(0,0,0,0.7);
  background-image: rgba(0,0,0,0.7);
  background-position: center;
  background-size: cover;
  padding-left: 8%;
  padding-right: 8%;
}

/* App.css */
.parallax {
  /* The image used */
  background-image: url('../public/islamic/QuranReading.jpg');


  /* Full height */
  height: 100vh;
  
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 75% 80%, 50% 90%, 25% 80%, 0 90%);

}

.parallax1 {
  /* The image used */
  background-image: url('../src/Images/parallax1.jpg');

  /* Full height */
  height: 10vh;
  
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* clip-path: polygon(0 0, 100% 0, 100% 90%, 75% 80%, 50% 90%, 25% 80%, 0 90%); */

}
.parallax2 {
  /* The image used */
  background-image: url('../src/Images/parallax1.jpg');


  /* Full height */
  height: 100vh;
  
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* clip-path: polygon(0 0, 100% 0, 100% 90%, 75% 80%, 50% 90%, 25% 80%, 0 90%); */

}


.logo {
  font-weight: bold; /* Adjust font weight as needed */
}


.content {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8); /* Optional: Background color for content sections */
}

.box{
  border-radius: 10px;
  padding: 15px 25px;
  box-sizing: border-box;
  cursor: pointer;
  margin: 10px 15px;
  background-position: center;
  background-size: cover;
  box-shadow: 5px 5px 20px rgb(116, 189, 223);
  
}

.box1{
  border-radius: 10px;
  padding: 15px 0px;
  box-sizing: border-box;
  cursor: pointer;
  margin: 10px;
  margin-right: 600px;
  margin-left: 100px;
  background-position: center;
  background-size: cover;
  box-shadow: 5px 5px 20px black;

}
.box2{
  border-radius: 10px;
  padding: 15px 10px;
  box-sizing: border-box;
  cursor: pointer;
  margin: 10px;
  margin-left: 600px;
  margin-right: 100px;
  background-position: center;
  background-size: cover;
  box-shadow: 5px 5px 20px black;

}




/* footer */
.footer {
      
      background-size: 400% 400%;
      animation: gradientAnimation 10s ease infinite;
      overflow: hidden;
      border-radius: 8px;
      /* background-image: linear-gradient( 135deg, #05060894 10%,#0e0f14a4, #0e0f1688 100%); */
      backdrop-filter: blur(10px); /* Blurry effect */
      overflow: hidden;
      /* background-color: rgb(163, 154, 154) ; */
}


/* src/components/ImageComponent.css */

/* .image-container { */
  /* text-align: center; Centers the image horizontally */
  /* margin: 20px 0; Adds some vertical margin */
/* } */
.image-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: auto;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  display: block;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 75% 80%, 50% 90%, 25% 80%, 0 90%);
}

.waving-image {
  max-width: 100%; /* Ensures the image doesn't exceed the container's width */
  height: auto; /* Maintains the aspect ratio */
  border-radius: 8px; /* Optional: Adds rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow */
  animation: wave 3s infinite; /* Adds the waving animation */
}

/* @keyframes wave {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
} */

.cut{
  clip-path: polygon(0 0, 100% 0, 100% 90%, 55% 100%, 55% 100%, 0 90%);
}

.separator {
  width: full; /* Width of the separator */
  height: 10px; /* Height of the separator */
  background-color: #ccc; /* Color of the separator */
}

/* LoadingScreen.css */
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 9999;
  font-size: 24px;
  color: #000;
}
